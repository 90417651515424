.nav-link {
  text-transform: capitalize;
}

.dropdown-menu {
  padding: 0;
  @include shadowbox-border;
  border-radius: 0;
}

.dropdown-menu .dropdown-item, .dropdown-menu li > a {
  padding: 8px 16px;
  margin: 0;
  color: $link;
  font-size: 13px;
  transition: background-color .5s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background-color: $body-bg;
    color: $link-hover;
  }
}

button.nav-link {
  background: transparent;
  border: none;
  box-shadow: none;
}

.dropdown-divider {
  margin: 0;
  border-top: 1px solid $border-color;
}

.pagination > .page-item.active > a, .pagination > .page-item.active > a:focus, .pagination > .page-item.active > a:hover, .pagination > .page-item.active > span, .pagination > .page-item.active > span:focus, .pagination > .page-item.active > span:hover {
  background-color: #f0f0f0;
  border-color: #e0e0e0;
  color: $title-color;
  box-shadow: none;
}

.title, .card-title, .info-title, .footer-brand, .footer-big h5, .footer-big h4, .media .media-heading {
  font-weight: bold;
  font-family: $type-display;
}

.card,
.panel {
  margin-bottom: 24px;
  margin-top: 24px;
}


.hidden {
  display: none;
}


/* sidebar update */
.side-nav.resizing .left-link {
  width: 48px;
  height: 48px;
}

@media (max-width: 1365px) {
  .dashboard-side .side-profile img {
    width: 48px;
    height: 48px;
  }
}
.left-nav {
  padding: 0;
}

.left-link {
  display: initial;
}

.dashboard-side.left-side.expand {
  .left-link {
    display: block;
  }
}



/* dashboard right */
@media (max-width: 1365px) {
  .dashboard .right-side .dash-right .list-box {
    padding: 0;
  }

  .dashboard .right-side .dash-right {
    height: 100%;

    .list-text p {
      margin-bottom: 0;
    }

  }
}


@media (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  .dashboard .right-side .dash-right {
    height: 100%;
  }

  .dash-right .list-box .list-content {
    margin-bottom: 0;
  }

}

/* end dashboard right */
