// @include aspect-ratio(16,9);
//   @include aspect-ratio(4,3);
//   @include aspect-ratio(4,4, true);

@mixin aspect-ratio($x,$y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + '%');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @if $pseudo {
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

// @include clearfix;

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}



// font-size: rem(20)
// margin: rem(40) rem(40);

@function strip-units($value) {
  @return ($value / ($value * 0 + 1));
}
@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  $base: 16px;
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1rem;
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

// @include responsive-ratio(16,9);

@mixin responsive-ratio($x,$y, $pseudo: false) {
  $padding: unquote( ( $y / $x ) * 100 + '%' );
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

// @include input-placeholder {
//        color: $grey;
//    }

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


// @include mq('tablet-wide') {
//        padding-top: 4rem;
//        font-size: 2.4rem;
//    }

$breakpoints: (
  "phone":        360px,
  "phone-wide":   480px,
  "phablet":      600px,
  "tablet-small": 640px,
  "tablet":       768px,
  "notebook":  1024px,
  "desktop":      1368px,
  "mac13":        1280px,
  "desktop-wide": 1440px,
  "desktop16":    1600px,
  "desktop-large":      1920px
);
@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin hardware($backface: true, $perspective: 1000) {
  @if $backface {
    backface-visibility: hidden;
  }
  perspective: $perspective;
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// @include keyframes(fade-out) {
//  0% { opacity: 1; }
//  90% { opacity: 0; }
//  }
// @include animation('fade-out 5s 3');

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// @include image-2x("logo2x.png", 100px, 25px);

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

// @include background-gradient(red, black, 'vertical');

@mixin background-gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

// @include font-face('gotham', '/fonts/gotham');

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
    url($file-name + '.woff') format('woff'),
    url($file-name + '.ttf')  format('truetype'),
    url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}


// @include opacity(.4);
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}

// Positioning helpers
// @include relative;
// @include absolute(top 100% left 0);
// @include fixed(top 0 left 0);
@mixin absolute($args: '') {
  @include position(absolute, $args);
}

@mixin fixed($args: '') {
  @include position(fixed, $args);
}

@mixin relative($args: '') {
  @include position(relative, $args);
}

@mixin alerted() {
  &:before {
    content: '';
    position: absolute;
    top: 6px; right: 6px;
    height: 8px; width: 8px;
    @include border-radius(10px);
    background-color: gold;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0; right: 0;
    height: 20px; width: 20px;
    @include border-radius(20px);
    background-color: transparent;
    border: solid gold;
    border-width: 2px; // animates
    @include box-sizing(border-box);
    @include animation($name: alertMe);
  }
}

@keyframes alertMe {
  // -vendor prefixes omitted for brevity, but should be used in production
  from {
    border-width: 3px;
    border-color: gold;
  }
  to {
    border-width: 0;
    border-color: rgba(gold, .1);
  }
}



// @include gradient(#07c, #06f, vertical);
@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

//@mixin shadowbox {
  //box-shadow: 0 5px 20px rgba(0,0,0,.05);
//}

@mixin shadowbox {
  box-shadow: 0px 15px 75px rgba(139, 140, 185, .118178);
}

@mixin shadowbox-border {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .03);
  border: 1px solid #f9f9f9;
}

@mixin shadowbox-hover {
  box-shadow: 0 16px 32px 0 rgba(0,15,30,.08);
}

@mixin shadowleft {
  box-shadow: 1px 0 20px rgba(0,0,0,.08);
}

@mixin shadowright {
  box-shadow: 5px 1px 40px rgba(0,0,0,.1);
}

@mixin shadowheader {
  //box-shadow: 0px 4px 30px -4px rgba(0, 51, 90, 0.1);
  //box-shadow: 0px 10px 24px 0px rgba(82, 63, 105, 0.1);
  //box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;
  box-shadow: 0 2px 35px 0 rgba(0, 0, 0, .1);
}

@mixin shadowdropdown {
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}

@mixin shadownotif {
  box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 6px 9px rgba(0,0,0,.2);
}

@mixin shadowbutton {
  //box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08);
  box-shadow: 0 4px 6px rgba(50,50,93,.08), 0 1px 3px rgba(0,0,0,.05);
}

@mixin shadowbutton-clean {
  box-shadow: rgba(42, 47, 69, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(42, 47, 69, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

@mixin shadowdrop {
  box-shadow: 0 0 0 1px rgba(136,152,170,.1),0 15px 35px 0 rgba(49,49,93,.1),0 5px 15px 0 rgba(0,0,0,.08);
}

@mixin flex-horizontal {
  display: flex;
  flex-flow: row wrap;
}

@mixin flex-vertical {
  display: flex;
  flex-flow: column wrap;
}
