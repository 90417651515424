// font family

@mixin font-source-sans($size: false, $colour: false, $weight: false,  $lh: false) {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  @if $size { font-size: $size; }
  @if $colour { color: $colour; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
}

$base-font-size: 14px;
$base-line-height-ratio: 1.457;
$tight-line-height-ratio: 1.2;
$body-copy-line-height-ratio: 1.6;
$base-font-family: "Open Sans", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont !default;


$body-font:"Open Sans", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont !default;
$type-display:  "Open Sans", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont !default;
$font-title:  "Open Sans", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont !default;
$type-display-lite:  "Open Sans", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont !default;
$type-button: "Open Sans", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont !default;
$type-input: "Open Sans", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont !default;
$type-label: "Open Sans", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, -apple-system, BlinkMacSystemFont !default;
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Main brand color
$brand: rgba(190, 30, 45, 1) !default;
$lite-brand: #FCF4F4 !default;

// layout color
$body-bg: #F3F5F7 !default;


//$border-color: #f0f0f0 !default;
//$border-color: #EFEFF9 !default;
//$border-color: #F7F7FC !default;
$border-color: #F1F1F7 !default;
$text-color: #595959 !default;
$text-color-strong: #595959 !default;


//$title-color: #01042b !default;
$title-color: #171C26 !default;

$subtitle-color: #007bbe !default;

//$type-grey: #a3acb9 !default;
$type-grey: #909090 !default;
$type-grey-lite: rgb(178, 189, 199) !default;

$link-hover: #2A598A !default;
$link-color: #3A7CC1 !default;
$link: #3A7CC1 !default;

$red: rgba(190, 30, 45, 1) !default;
$lite-blue : #edf3fc !default;
$blue: #4baadb !default;
//$blue: #007bbe !default;
$blue2: #1F2634 !default;
//$orange: rgb(234,150,0) !default;
$orange: #FF9A85 !default;
//$yellow: rgb(255,213,0)!default;
$yellow: #fdc148 !default;
$yellow2: rgb(234,150,0)!default;

//$green: rgb(153,204,0) !default;
//$green: #489E92 !default;
$green: #60C0C6 !default;
$navy: #111213 !default;

$border-input: #F0F0F0;
$stripe: rgba(247, 252, 250, .55) !default;

$lite-form: #f8f8f8 !default;

$bg-lite: #EEF0F1 !default;

$table-strip: #F8FBFE !default;
$table-border: #EEF7FF !default;

$bronze: #CD9E66 !default;
$gold: #DBB539 !default;
$silver: #D7D7D7 !default;
